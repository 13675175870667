import React from "react";
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import ProductBanner from '../../components/ProductDetails/ProductBanner/ProductBanner'
import FeatureDescription from '../../components/ProductDetails/FeatureDescription/FeatureDescription'
import AreaGuid from '../../components/ProductDetails/AreaGuid/AreaGuid'

const ProductDetails =()=> {
  const [state,setState] = React.useState({
    showMenu:false
})

const handlerClick = () => { 
        setState({...state,showMenu:!state.showMenu})
        var root = document.getElementsByTagName('html')[0];
        if (state.showMenu == true) {
            document.body.className = ''; 
            root.className = ''; 
        } else {
            document.body.classList.add("overflow-hidden");   
            root.classList.add("overflow-hidden");  
        }
   
}

return(
    <>
    <div className={state.showMenu ? "wrapper open-search-block" : "wrapper"}>
     <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
        <ProductBanner/>
        <FeatureDescription/>
        <AreaGuid/>

        <Footer />
        </div>  
        </>
    )
}

export default ProductDetails;